exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-build-your-mvp-tsx": () => import("./../../../src/pages/build-your-mvp.tsx" /* webpackChunkName: "component---src-pages-build-your-mvp-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-cloud-engineering-tsx": () => import("./../../../src/pages/cloud-engineering.tsx" /* webpackChunkName: "component---src-pages-cloud-engineering-tsx" */),
  "component---src-pages-cloud-services-tsx": () => import("./../../../src/pages/cloud-services.tsx" /* webpackChunkName: "component---src-pages-cloud-services-tsx" */),
  "component---src-pages-cloud-strategy-and-assessment-tsx": () => import("./../../../src/pages/cloud-strategy-and-assessment.tsx" /* webpackChunkName: "component---src-pages-cloud-strategy-and-assessment-tsx" */),
  "component---src-pages-cloud-support-tsx": () => import("./../../../src/pages/cloud-support.tsx" /* webpackChunkName: "component---src-pages-cloud-support-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-containers-and-kubernetes-tsx": () => import("./../../../src/pages/containers-and-kubernetes.tsx" /* webpackChunkName: "component---src-pages-containers-and-kubernetes-tsx" */),
  "component---src-pages-ecommerce-tsx": () => import("./../../../src/pages/ecommerce.tsx" /* webpackChunkName: "component---src-pages-ecommerce-tsx" */),
  "component---src-pages-hire-us-tsx": () => import("./../../../src/pages/hire-us.tsx" /* webpackChunkName: "component---src-pages-hire-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-monitoring-and-observability-tsx": () => import("./../../../src/pages/monitoring-and-observability.tsx" /* webpackChunkName: "component---src-pages-monitoring-and-observability-tsx" */),
  "component---src-pages-our-process-tsx": () => import("./../../../src/pages/our-process.tsx" /* webpackChunkName: "component---src-pages-our-process-tsx" */),
  "component---src-pages-our-work-index-tsx": () => import("./../../../src/pages/our-work/index.tsx" /* webpackChunkName: "component---src-pages-our-work-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-technologies-bigcommerce-tsx": () => import("./../../../src/pages/technologies/bigcommerce.tsx" /* webpackChunkName: "component---src-pages-technologies-bigcommerce-tsx" */),
  "component---src-pages-technologies-cloud-tsx": () => import("./../../../src/pages/technologies/cloud.tsx" /* webpackChunkName: "component---src-pages-technologies-cloud-tsx" */),
  "component---src-pages-technologies-magento-tsx": () => import("./../../../src/pages/technologies/magento.tsx" /* webpackChunkName: "component---src-pages-technologies-magento-tsx" */),
  "component---src-pages-technologies-salesforce-commerce-cloud-tsx": () => import("./../../../src/pages/technologies/salesforce-commerce-cloud.tsx" /* webpackChunkName: "component---src-pages-technologies-salesforce-commerce-cloud-tsx" */),
  "component---src-pages-technologies-shopify-tsx": () => import("./../../../src/pages/technologies/shopify.tsx" /* webpackChunkName: "component---src-pages-technologies-shopify-tsx" */),
  "component---src-pages-technologies-woocommerce-wordpress-tsx": () => import("./../../../src/pages/technologies/woocommerce-wordpress.tsx" /* webpackChunkName: "component---src-pages-technologies-woocommerce-wordpress-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-ui-ux-solution-tsx": () => import("./../../../src/pages/ui-ux-solution.tsx" /* webpackChunkName: "component---src-pages-ui-ux-solution-tsx" */),
  "component---src-pages-your-startup-partner-tsx": () => import("./../../../src/pages/your-startup-partner.tsx" /* webpackChunkName: "component---src-pages-your-startup-partner-tsx" */),
  "component---src-templates-our-work-index-js": () => import("./../../../src/templates/OurWork/index.js" /* webpackChunkName: "component---src-templates-our-work-index-js" */)
}

